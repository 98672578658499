import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import showModule from './modules/show';
import selectedFileModule from './modules/selectedFile';
import userModule from './modules/user';
import editorialPassModule from './modules/editorialPass';
import messageModule from './modules/message';
import subscriptionModule from './modules/subscription';
import buttonGroupModule from './modules/buttongroups';
import azuredataModule from './modules/azuredata';

Vue.use(Vuex);

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuex',
      reducer(val) {
        if (!localStorage.token) {
          // return empty state when user logged out
          return {};
        }
        return val;
      },
    }),
  ],
  modules: {
    user: userModule,
    editorialPass: editorialPassModule,
    show: showModule,
    selectedFile: selectedFileModule,
    message: messageModule,
    subscription: subscriptionModule,
    buttonGroups: buttonGroupModule,
    azuredata: azuredataModule,
  },
  state: state,
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    RESET_STATE({ commit }) {
      commit('RESET_STATE');
      commit('message/RESET_STATE');
      commit('show/RESET_STATE');
      commit('selectedFile/RESET_STATE');
      commit('editorialPass/RESET_STATE');
      commit('subscription/RESET_STATE');
      commit('user/RESET_STATE');
    },
  },
  getters: {},
});
