import axios from '@/lib/http';
import LocalStorageService from '@/services/LocalStorageService';
const localStorageService = LocalStorageService.getService();

const getDefaultState = () => {
  return {
    queryString: '',
  };
};

const state = getDefaultState();

const mutations = {
  SET_QUERYSTRING(state, string) {
    state.queryString = string;
  },
};

const actions = {
  saveQueryString(context, string) {
    context.commit('SET_QUERYSTRING',string);
  },
  getQueryString(context) {
    return context.queryString;
  },
};

const getters = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
