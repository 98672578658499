import Vue from 'vue';
import Router from 'vue-router';
import axios from '@/lib/http';
import store from '@/store';
import LocalStorageService from '@/services/LocalStorageService';


// Lazyloaded Views
const LandingPage = () => import('@/views/LandingPage.vue');
const LoginPage = () => import('@/views/LoginPage.vue');
const SignUpPage = () => import('@/views/SignUpPage.vue');
const CreateDemoUser = () => import('@/views/CreateDemoUser.vue');
const CreateLegacyUser = () => import('@/views/CreateLegacyUser.vue');
const Library = () => import('@/views/Library.vue');
const ProfilePage = () => import('@/views/ProfilePage.vue');
const AccountSettings = () => import('@/views/AccountSettings.vue');
const ModifySubscription = () => import('@/views/ModifySubscription.vue');
const AllMedia = () => import('@/views/AllMedia.vue');
const Quizzes = () => import('@/views/Quizzes.vue');
const QuizResults = () => import('@/views/QuizResults.vue');
const AddQuiz = () => import('@/views/AddQuiz.vue');
const QuizPreview = () => import('@/views/QuizPreview.vue');
const ReportPage = () => import('@/views/ReportPage.vue');
const Admin = () => import('@/views/Admin.vue');
const AdminUserControl = () => import('@/views/AdminUserControl.vue');
const PasswordChange = () => import('@/views/PasswordChange.vue');
const PasswordReset = () => import('@/views/PasswordReset.vue');
const TheStudio = () => import('@/views/TheStudio.vue');
const Expired = () => import('@/views/Expired.vue');
const NotFound = () => import('@/views/NotFound.vue');
const ImagePack = () => import('@/views/ImagePack.vue');
const PaymentDetails = () => import('@/views/PaymentDetails.vue');
const PrivacyPolicy = () => import('@/views/PrivacyPolicy.vue');

const ConfirmPayment = () => import('@/views/ConfirmPayment.vue');
const RenewSubscription = () => import('@/views/RenewSubscription.vue');
const VerifiedEmail = () => import('@/views/VerifiedEmail.vue');

const Analytics = () => import('@/views/Analytics.vue');

const localStorageService = LocalStorageService.getService();

Vue.use(Router);

const nonLegacyRoutes = [
  'modify-subscription',
  'renew-subscription',
  'payment-details',
  'confirm-payment',
];

function hasSubscription() {
  const stripeUser = store.state.user.stripeUser;

  let hasPlan = false;
  if (!stripeUser){
    return false;
  }

  if(stripeUser.plan === 'silver' ||
     stripeUser.plan === 'gold' ||
     stripeUser.plan === 'platinum' ||
     stripeUser.plan === 'admin' ||
     stripeUser.plan === 'demo'){
      hasPlan = true;
     }

  let hasSubscriptionId = false;
  if(stripeUser.id && stripeUser.id.length > 10){
    hasSubscriptionId = true;
  }
  if(hasSubscriptionId && hasPlan) {
    return true;
  } else {
    return false;
  }
  // return (
  //   stripeUser &&
  //   stripeUser.subscriptions &&
  //   stripeUser.subscriptions.data &&
  //   stripeUser.subscriptions.data[0]
  // );
}

function subscriptionActiveOrTrialing() {
  const stripeUser = store.state.user.stripeUser;

  return (
    (hasSubscription() &&
      stripeUser.subscription_status === 'active') ||
    stripeUser.subscription_status === 'trialing'
  );
}

function subscriptionExpired() {
  const stripeUser = store.state.user.stripeUser;
  return (
    stripeUser &&
    stripeUser.subscriptions &&
    stripeUser.subscriptions.data &&
    !stripeUser.subscriptions.data[0]
  );
}

function requiresAction() {
  const stripeUser = store.state.user.stripeUser;
  return (
    hasSubscription &&
    stripeUser.subscriptions.data[0].latest_invoice.payment_intent &&
    stripeUser.subscriptions.data[0].latest_invoice.payment_intent.status ===
      'requires_action'
  );
}

function isLoggedIn(to, from, next) {
  // Täällä infinite loop??

  const user = store.state.user.user;

  if (localStorageService.getAccessToken() && user) {
    if (store.state.editorialPass.pass) {
      // Editorial pass
      return next(`/editorial/${store.state.editorialPass.show_uuid}`);
    }
    if (user.legacy) {
      // Legacy user
      if (nonLegacyRoutes.includes(to.name)) return next('/library');
      return next();
    }

    if (!user.verified) {
      // Not verified
      return next('/verified');
    }

    if (hasSubscription()) {
      if (subscriptionActiveOrTrialing()) {
        if (
          to.name === 'verified' ||
          to.name === 'confirm-payment' ||
          to.name === 'renew-subscription'
        )
          return next('/library');
        return next();
      }
      if (requiresAction()) {
        if (to.name === 'confirm-payment') {
          return next();
        }
        return next('/confirm-payment');
      }
      if (subscriptionActiveOrTrialing()) {
        return next();
      }

      if (to.name === 'renew-subscription') {
        return next();
      }
      return next('/renew-subscription');
    }

    if (subscriptionExpired()) {
      // Subscription expired
      if (to.name === 'renew-subscription') {
        return next();
      }
      return next('/renew-subscription');
    }

    return next('/signup');
  }

  return next('/login');
}

function routeToLibrary(to, from, next) {
  next('/library');
}
function checkCoupon(to, from, next) {
  const coupon = to.params.coupon;
  store.commit('subscription/setCoupon', coupon);
  next('/signup');
}
function isLoggedInWithEditorialPass(to, from, next) {
  if (localStorageService.getAccessToken()) {
    if (store.state.user.user) return next('/library');
    const { expires } = store.state.editorialPass;
    const now = new Date().getTime();
    if (expires && expires < now) {
      return next({
        path: '/logout',
        query: { error: 'Editorial pass has expired.' },
      });
    }
    next();
  } else {
    next('/login');
  }
}

function removeToken(to, from, next) {
  axios
    .delete('/auth/logout', {
      data: {
        token: localStorageService.getAccessToken(),
        refreshToken: localStorageService.getRefreshToken(),
      },
    })
    .then(() => {
      localStorageService.clearTokens();
      store.dispatch('RESET_STATE');
      return next({ path: '/login', query: to.query });
    })
    .catch(() => {
      localStorageService.clearTokens();
      store.dispatch('RESET_STATE');
      return next({ path: '/login', query: to.query });
    });
}

function isAdmin(to, from, next) {
  if (localStorageService.getAccessToken() && store.state.user.user.admin) {
    next();
  } else {
    next('/logout');
  }
}

function activeSubscriptionRedirect(to, from, next) {
  const user = store.state.user.user;

  if (user && user.legacy) return next('/library');

  if (!localStorageService.getAccessToken() && user) return next();
  if (hasSubscription()) {
    if (subscriptionActiveOrTrialing()) {
      return next('/library');
    }
    return next('/renew-subscription');
  }

  if (subscriptionExpired()) {
    return next('/renew-subscription');
  }

  return next();
}

// function hasQueryParams(route) {
//   return !!Object.keys(route.query).length
// }

// Router.beforeEach((to, from, next) => {
//    if(!hasQueryParams(to) && hasQueryParams(from)){
//     next({name: to.name, query: from.query});
//   } else {
//     next()
//   }
// })

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: LandingPage,
      beforeEnter: routeToLibrary,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      beforeEnter: activeSubscriptionRedirect,
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: removeToken,
    },
    {
      path: '/a/:coupon',
      name: 'Affiliate',
      beforeEnter: checkCoupon,
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpPage,
      beforeEnter: activeSubscriptionRedirect,
    },
    {
      path: '/create-demo',
      name: 'create-demo',
      component: CreateDemoUser,
      beforeEnter: isAdmin,
    },
    {
      path: '/create-legacy',
      name: 'create-legacy',
      component: CreateLegacyUser,
      beforeEnter: isAdmin,
    },
    {
      path: '/library',
      name: 'library',
      component: Library,
      beforeEnter: isLoggedIn,
    },
    // {
    //   path: '/analytics',
    //   name: 'analytics',
    //   component: Analytics,
    // },
    {
      path: '/profile',
      name: 'profile',
      component: AccountSettings,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/profile/menu-media',
      name: 'menu-media',
      component: ProfilePage,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/modify-subscription',
      name: 'modify-subscription',
      component: ModifySubscription,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/renew-subscription',
      name: 'renew-subscription',
      component: RenewSubscription,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/payment-details',
      name: 'payment-details',
      component: PaymentDetails,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
    },
    {
      path: '/confirm-payment',
      name: 'confirm-payment',
      component: ConfirmPayment,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/show/:show_uuid',
      name: 'show',
      component: TheStudio,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/editorial/:show_uuid',
      name: 'editorial-show',
      component: TheStudio,
      beforeEnter: isLoggedInWithEditorialPass,
    },
    {
      path: '/allmedia',
      name: 'allmedia',
      component: AllMedia,
      beforeEnter: isLoggedIn,
    },
    // {
    //   path: '/buttons',
    //   name: 'buttons',
    //   component: UserButtons,
    //   beforeEnter: isLoggedIn,
    // },
    {
      path: '/quizzes',
      name: 'quizzes',
      component: Quizzes,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/quiz-results/:quiz_uuid',
      name: 'quiz-results',
      component: QuizResults,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/quiz/add',
      name: 'add-quiz',
      component: AddQuiz,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/quiz/:quiz_uuid',
      name: 'quiz-preview',
      component: QuizPreview,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      beforeEnter: isAdmin,
    },
    {
      path: '/admin/user/:user_uuid',
      name: 'user-control',
      component: AdminUserControl,
      beforeEnter: isAdmin,
    },
    {
      path: '/report',
      name: 'report',
      component: ReportPage,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/password',
      name: 'password',
      component: PasswordChange,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: PasswordReset,
      beforeEnter: activeSubscriptionRedirect,
    },

    {
      path: '/image-pack',
      name: 'image-pack',
      component: ImagePack,
      beforeEnter: isLoggedIn,
    },
    {
      path: '/expired',
      name: 'expired',
      component: Expired,
    },
    {
      path: '/verified',
      name: 'verified',
      component: VerifiedEmail,
    },
    {
      path: '/**',
      name: 'not-found',
      alias: '/not-found',
      component: NotFound,
    },
  ],
});

router.afterEach(() => {
  const loadingMessages = store.getters['message/loading'];
  for (const message in loadingMessages) {
    store.commit('message/DELETE_MESSAGE', message);
  }
});

export default router;
