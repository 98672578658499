import axios from '@/lib/http';
import LocalStorageService from '@/services/LocalStorageService';
const localStorageService = LocalStorageService.getService();
const getDefaultState = () => {
  return {
    pass: null,
    show_uuid: null,
    plan: null,
    expires: null,
    username: null,
    show: null,
    files: [],
    images: [],
    videos: [],
    startFile: null,
    percentageOfDataUsed: 0,
    empty: true,
    userQuizzes: [],
    userButtons: [],
    guestPasses: [],
  };
};

const state = getDefaultState();

const mutations = {
  SET_PASS(state, pass) {
    state.pass = pass;
  },
  SET_SHOW_UUID(state, show_uuid) {
    state.show_uuid = show_uuid;
  },
  SET_PLAN(state, plan) {
    state.plan = plan;
  },
  SET_EXPIRES(state, expires) {
    state.expires = expires;
  },
  SET_USERNAME(state, username) {
    state.username = username;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  // SHOW
  SET_SHOW(state, show) {
    state.show = show;
  },
  SET_FILES(state, files) {
    state.files = files;
  },
  SET_IMAGES(state, images) {
    const start = images.find(
      image => image.name.split('_')[0] === 'ShowStart',
    );
    if (start) {
      images.splice(images.indexOf(start), 1);
      state.images = [start, ...images];
    } else {
      state.images = images;
    }
  },
  SET_VIDEOS(state, videos) {
    const start = videos.find(
      video => video.name.split('_')[0] === 'ShowStart',
    );
    if (start) {
      videos.splice(videos.indexOf(start), 1);
      state.videos = [start, ...videos];
    } else {
      state.videos = videos;
    }
  },
  SET_START_FILE(state, file) {
    state.startFile = file;
  },
  SET_PERCENTAGE(state, percentage) {
    state.percentageOfDataUsed = percentage;
  },
  SET_EMPTY(state, bool) {
    state.empty = bool;
  },
  SET_USER_BUTTONS(state, buttons) {
    state.userButtons = buttons;
  },
  SET_USER_QUIZZES(state, quizzes) {
    state.userQuizzes = quizzes;
  },
  SET_PASSES(state, passes) {
    state.guestPasses = passes;
  },
  SET_IMAGE_FILE_START(state, imageObj) {
    const image = state.images.find(
      image => image.uuid === imageObj.image_uuid,
    );
    if (image) {
      image.start_pos = imageObj.slider;
    }
  },
  SET_VIDEO_FILE_START(state, videoObj) {
    const video = state.videos.find(
      video => video.uuid === videoObj.video_uuid,
    );
    if (video) {
      video.start_pos = videoObj.slider;
    }
  },
};

const actions = {
  async LOGIN(context, body) {
    const { data } = await axios.post('/auth/login/editorial-pass', body);
    localStorageService.setToken(data);
    context.commit('SET_PASS', data.pass.pass);
    context.commit('SET_SHOW_UUID', data.pass.show_uuid);
    context.commit('SET_PLAN', data.pass.show_user_plan);
    context.commit('SET_USERNAME', data.pass.show_username);
    context.commit('SET_EXPIRES', data.pass.expires);
  },
  // SHOW
  async GET_SHOW(context) {
    const { data } = await axios.get('/api/editorial-pass/show');
    context.commit('SET_SHOW', data);
  },
  async GET_FILES(context) {
    const { data } = await axios.get('/api/editorial-pass/files');
    context.commit('SET_FILES', data);
  },
  async GET_IMAGES(context) {
    const { data } = await axios.get('/api/editorial-pass/images');
    context.commit('SET_IMAGES', data);
  },
  async GET_VIDEOS(context) {
    const { data } = await axios.get('/api/editorial-pass/videos');
    context.commit('SET_VIDEOS', data);
  },
  async GET_PERCENTAGE_OF_DATA_USED(context) {
    const { data } = await axios.get('/api/editorial-pass/data');
    context.commit('SET_PERCENTAGE', data.percentage);
  },
  async GET_USER_BUTTONS(context) {
    const { data } = await axios.get('/api/editorial-pass/user-buttons');
    context.commit('SET_USER_BUTTONS', data);
  },
  async GET_USER_QUIZZES(context) {
    const { data } = await axios.get('/api/editorial-pass/user-quizzes');
    context.commit('SET_USER_QUIZZES', data);
  },
  async GET_GUEST_PASSES(context) {
    const { data } = await axios.get('/api/editorial-pass/guest-passes');
    context.commit('SET_PASSES', data);
  },
  async UPDATE_IMAGE_START(context, body) {
    await axios.post('/api/editorial-pass/start-position/image', body);
    context.commit('SET_IMAGE_FILE_START', body);
  },
  async UPDATE_VIDEO_START(context, body) {
    await axios.post('/api/editorial-pass/start-position/video', body);
    context.commit('SET_VIDEO_FILE_START', body);
  },
  async RELOAD_DATA({ dispatch, commit, state }) {
    await dispatch('GET_PERCENTAGE_OF_DATA_USED');
    await dispatch('GET_FILES');
    await dispatch('GET_IMAGES');
    await dispatch('GET_VIDEOS');

    const allFiles = [...state.images, ...state.videos];

    const startFile =
      allFiles.find(file => file.name.split('_')[0] === 'ShowStart') || null;

    if (startFile) {
      const type = startFile.path.includes('/rooms/') ? 'image' : 'video';
      const fileObj = {
        file: startFile,
        type,
      };
      commit('SET_START_FILE', startFile);
      commit('selectedFile/SET_FILE', fileObj, { root: true });
      commit('SET_EMPTY', false);
    } else {
      commit('SET_START_FILE', null);
      if (state.images.length > 0) {
        const fileObj = {
          file: state.images[0],
          type: 'image',
        };
        commit('selectedFile/SET_FILE', fileObj, { root: true });
        commit('SET_EMPTY', false);
      } else if (state.videos.length > 0) {
        const fileObj = {
          file: state.videos[0],
          type: 'video',
        };
        commit('selectedFile/SET_FILE', fileObj, { root: true });
        commit('SET_EMPTY', false);
      } else {
        commit('SET_EMPTY', true);
      }
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
