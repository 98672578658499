// import axios from '@/lib/http';
const getDefaultState = () => {
  return {
    messages: [],
  };
};

const state = getDefaultState();

const mutations = {
  ADD_MESSAGE(state, message) {
    state.messages.push(message);
  },
  DELETE_MESSAGE(state, message) {
    state.messages.splice(state.messages.indexOf(message), 1);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {};

const getters = {
  loading: state => {
    return state.messages.filter(message => message.type === 'loading');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
