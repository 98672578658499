const products = [
  {
    id: 'prod_JD5vKnEGnufRGJ',
    name: '360Editor - Silver',
    maxShows: 5,
    description: 'Web based 360editor and publishing platform for VR-Content.',
    details: [
      '100 GB storage (20 GB/show)',
      'Embedded media player',
      'Shareable links to shows',
      '360 Images/360 Videos',
      '2D Images/2D Videos',
      'Background audio',
      'Interactive features',
      'Link shows together',
    ],
    images: ['https://files.stripe.com/links/fl_test_WY31xkxOAA0b4n6wIwx2EPGE'],
  },
  {
    id: 'prod_JD5wO9XhO2IlYU',
    name: '360Editor - Gold',
    maxShows: 15,
    description: 'Web based 360editor and publishing platform for VR-Content.',
    details: [
      '300 GB storage (20 GB/show)',
      'Embedded media player',
      'Shareable links to shows',
      '360 Images/360 Videos',
      '2D Images/2D Videos',
      'Background audio',
      'Interactive features',
      'Link shows together',
    ],
    images: ['https://files.stripe.com/links/fl_test_RCmkfFKubfLDo6uy8SRlWEUZ'],
  },
  {
    id: 'prod_JD5w54Yb9wfi8u',
    name: '360Editor - Platinum',
    maxShows: 50,
    description: 'Web based 360editor and publishing platform for VR-Content.',
    details: [
      '1 TB storage (20 GB/show)',
      'Embedded media player',
      'Shareable links to shows',
      '360 Images/360 Videos',
      '2D Images/2D Videos',
      'Background audio',
      'Interactive features',
      'Link shows together',
    ],
    images: ['https://files.stripe.com/links/fl_test_vIUpTb5irVP3sDOLrVIdJgbL'],
  },
];
const testProducts = [
  {
    id: 'prod_IkEwZigT2yj824',
    name: '360Editor - Silver',
    maxShows: 5,
    description: 'Web based 360editor and publishing platform for VR-Content.',
    details: [
      '20 Gb storage',
      'Embedded media player',
      'Shareable links to shows',
      '360 Images/360 Videos',
      '2D Images/2D Videos',
      'Background audio',
      'Interactive features',
      'Link shows together',
    ],
    images: ['https://files.stripe.com/links/fl_test_WY31xkxOAA0b4n6wIwx2EPGE'],
  },
  {
    id: 'prod_IkFM1xZLYz2tTZ',
    name: '360Editor - Gold',
    maxShows: 15,
    description: 'Web based 360editor and publishing platform for VR-Content.',
    details: [
      '20 Gb storage',
      'Embedded media player',
      'Shareable links to shows',
      '360 Images/360 Videos',
      '2D Images/2D Videos',
      'Background audio',
      'Interactive features',
      'Link shows together',
    ],
    images: ['https://files.stripe.com/links/fl_test_RCmkfFKubfLDo6uy8SRlWEUZ'],
  },
  {
    id: 'prod_IkFRutqCDM16Qe',
    name: '360Editor - Platinum',
    maxShows: 50,
    description: 'Web based 360editor and publishing platform for VR-Content.',
    details: [
      '20 Gb storage',
      'Embedded media player',
      'Shareable links to shows',
      '360 Images/360 Videos',
      '2D Images/2D Videos',
      'Background audio',
      'Interactive features',
      'Link shows together',
    ],
    images: ['https://files.stripe.com/links/fl_test_vIUpTb5irVP3sDOLrVIdJgbL'],
  },
];
export default process.env.NODE_ENV === 'production' ? products : testProducts;
