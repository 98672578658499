<template>
  <div v-if="messages.length > 0" class="wrapper">
    <div class="messages">
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="message mb-2"
        @click="deleteMessage(message)"
      >
        <div class="mr-3">
          <img :src="getImgUrl(message.type)" style="width:30px;height:30px;" />
        </div>
        <span>{{ message.message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'Message',
  computed: {
    ...mapState({
      messages: state => state.message.messages,
    }),
  },
  methods: {
    ...mapMutations({
      DELETE_MESSAGE: 'message/DELETE_MESSAGE',
    }),
    getImgUrl(type) {
      if (type === 'loading') {
        return require('../assets/messages/' + type + '.gif');
      }
      return require('../assets/messages/' + type + '.png');
    },
    deleteMessage(message) {
      if (message.type !== 'loading') {
        this.DELETE_MESSAGE(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  z-index: 100;
  @include flexCenter(column);
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  .messages {
    .message {
      pointer-events: all;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      transition: 0.2s;
      background: #f2f2f2;
      opacity: 0.96;
      color: #757575;
      border-bottom: 0 none;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
