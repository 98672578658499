<template>
  <div class="navbar navbar-default navbar-expand-md">
    <router-link to="/">
      <img src="../assets/360Editor.png" style="height: 40px" />
    </router-link>
    <button
      class="navbar-toggler navbar-light"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div id="navbarNav" class="collapse navbar-collapse">
      <div v-if="userAndToken" class="navbar-nav mr-auto">
        <router-link class="nav-link" :to="{ name: 'library' }"
          >LIBRARY</router-link
        >
        <!-- <router-link class="nav-link" :to="{ name: 'analytics' }"
          >ANALYTICS</router-link
        > -->
        <div v-if="user && user.admin" class="dropdown">
          <a
            id="dropdownMenuLink"
            class="nav-link dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            More
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <router-link class="nav-link" :to="{ name: 'admin' }"
                >Admin</router-link
              >
            </li>
            <li>
              <router-link class="nav-link" to="/create-legacy"
                >Create Legacy</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- <div style="color:red; font-weight: bold; font-size: 18px;">
          The latest version of 360Mediaplayer is available for download for each platform. Remember to download it to see the work you make.
        </div> -->
      <div v-if="userAndToken" class="navbar-nav ml-auto">
        <router-link class="nav-link" to="/profile">PROFILE</router-link>

        <router-link class="nav-link" to="/logout">LOGOUT</router-link>
      </div>
      <div v-else-if="editorial_pass" class="navbar-nav ml-auto">
        <router-link class="nav-link" to="/logout">LOGOUT</router-link>
      </div>
      <div v-else class="navbar-nav ml-auto">
        <!-- <router-link class="nav-link" to="/signup">SIGNUP</router-link> -->
        <router-link class="nav-link" to="/login">LOGIN</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'TheNavbar',
  computed: {
    ...mapState({
      user: state => state.user.user,
      editorial_pass: state => state.editorialPass.pass,
    }),
    userAndToken() {
      return this.user && localStorage.getItem('token');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
router-link {
  font-weight: bold;
  color: #2c3e50;
}

.navbar {
  font-size: 14px;
  border-bottom: 1px solid #2c3e50;
  padding: 12px 20px;
  background: white;
}

.router-link-exact-active {
  color: color(primary);
}
</style>
