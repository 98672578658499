const year = 'year';
const month = 'month';
const eur = 'eur';

const silver = 'prod_JD5vKnEGnufRGJ';
const gold = 'prod_JD5wO9XhO2IlYU';
const platinum = 'prod_JD5w54Yb9wfi8u';

const testProducts = {
  silver: 'prod_IkEwZigT2yj824',
  gold: 'prod_IkFM1xZLYz2tTZ',
  platinum: 'prod_IkFRutqCDM16Qe',
};

const prices = [
  {
    id: 'price_1IafjXJ9in2FvoUPCmMKxRnx',
    legacyIds: [],
    currency: eur,
    product: silver,
    recurring: {
      interval: month,
    },
    unit_amount: 9900,
  },
  {
    id: 'price_1IafjXJ9in2FvoUPoBwSbFOj',
    legacyIds: [],
    currency: eur,
    product: silver,
    recurring: {
      interval: year,
    },
    unit_amount: 99900,
  },
  {
    id: 'price_1IafkHJ9in2FvoUPY2UFxJL0',
    legacyIds: [],
    currency: eur,
    product: gold,
    recurring: {
      interval: month,
    },
    unit_amount: 14900,
  },
  {
    id: 'price_1IafkHJ9in2FvoUPPOtWY7K3',
    legacyIds: [],
    currency: eur,
    product: gold,
    recurring: {
      interval: year,
    },
    unit_amount: 149000,
  },
  {
    id: 'price_1IafkvJ9in2FvoUPgFult5RJ',
    legacyIds: [],
    currency: eur,
    product: platinum,
    recurring: {
      interval: month,
    },
    unit_amount: 19900,
  },
  {
    id: 'price_1IafkvJ9in2FvoUP5optTTWW',
    legacyIds: [],
    currency: eur,
    product: platinum,
    recurring: {
      interval: year,
    },
    unit_amount: 199000,
  },
];

const testPrices = [
  {
    id: 'price_1IYqH0J9in2FvoUPZjOjnQXZ',
    legacyIds: ['price_1IWINJJ9in2FvoUPnbrQ2Ktf'],
    currency: eur,
    product: testProducts.silver,
    recurring: {
      interval: month,
    },
    unit_amount: 9900,
  },
  {
    id: 'price_1IYqHBJ9in2FvoUPalefGudV',
    legacyIds: ['price_1IWINTJ9in2FvoUPdrmPhUUe'],
    currency: eur,
    product: testProducts.silver,
    recurring: {
      interval: year,
    },
    unit_amount: 99900,
  },
  {
    id: 'price_1IVcShJ9in2FvoUPDEtHNx1m',
    legacyIds: [],
    currency: eur,
    product: testProducts.gold,
    recurring: {
      interval: month,
    },
    unit_amount: 14900,
  },
  {
    id: 'price_1IVcSwJ9in2FvoUPo7wggnF6',
    legacyIds: [],
    currency: eur,
    product: testProducts.gold,
    recurring: {
      interval: year,
    },
    unit_amount: 149000,
  },
  {
    id: 'price_1IWINoJ9in2FvoUPE88gbQ1f',
    legacyIds: [],
    currency: eur,
    product: testProducts.platinum,
    recurring: {
      interval: month,
    },
    unit_amount: 19900,
  },
  {
    id: 'price_1IYqIyJ9in2FvoUPGrJEWU3E',
    legacyIds: ['price_1IWINvJ9in2FvoUPEyVSYLJl'],
    currency: eur,
    product: testProducts.platinum,
    recurring: {
      interval: year,
    },
    unit_amount: 199000,
  },
];
export default process.env.NODE_ENV === 'production' ? prices : testPrices;
