<template>
  <footer id="footer">
    <router-link v-if="user" class="report" :to="{ name: 'report' }"
      >Report A Bug</router-link
    >
    <a class="mediatalo" href="http://360mediahouse.com" target="_blank">
      <img src="../assets/360mediahouse.png" />
    </a>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'TheFooter',
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  width: 100vw;
  justify-content: space-between;
  padding: 10px 2vw;
  .report {
    font-size: 16px;
    transition: 0.4s;

    &:hover {
      color: color(primary);
      text-decoration: none;
    }
  }
  img {
    width: 150px;
  }
  .mediatalo {
    margin-left: auto;
  }
}
</style>
