<template>
  <div v-if="user && user.plan === 'demo'" class="alert-container">
    <div class="demo-user-alert">
      <div
        v-if="user.expires - new Date().getTime() > 0"
        ref="notExpired"
        class="alert-text"
        :class="{ more: more }"
      >
        Your demo ends in {{ expires }}.
        <a
          href="https://360mediahouse.com/start-using-vr-today/"
          target="_blank"
          >Contact 360Mediahouse</a
        >
        to upgrade your licence. After your demo expires, you wont be able to
        use your account.
      </div>
      <div v-else ref="expired" class="alert-text" :class="{ more: more }">
        Your demo has expired.
        <a
          href="https://360mediahouse.com/start-using-vr-today/"
          target="_blank"
          >Contact 360Mediahouse</a
        >
        to upgrade your licence.
      </div>
      <button v-if="!more && overflow" class="toggle-link" @click="more = true">
        More
      </button>
      <button
        v-else-if="more && !overflow"
        class="toggle-link"
        @click="more = false"
      >
        Less
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'DemoUserAlert',
  data() {
    return {
      more: false,
      overflow: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    expires() {
      if (this.user.expires == 0) {
        return 0;
      }
      const days = Math.floor(
        (this.user.expires - new Date().getTime()) / 1000 / 60 / 60 / 24,
      );
      if (days < 1) {
        const hours = Math.floor(
          (this.user.expires - new Date().getTime()) / 1000 / 60 / 60,
        );
        return `${hours} hours`;
      }
      return `${days} days`;
    },
  },
  mounted() {
    this.isOverflowing();

    window.addEventListener('resize', () => {
      this.isOverflowing();
    });
  },
  updated() {
    this.isOverflowing();
  },
  methods: {
    isOverflowing() {
      const expired = this.$refs.expired;
      const notExpired = this.$refs.notExpired;
      if (expired) {
        this.overflow =
          expired.offsetHeight < expired.scrollHeight ||
          expired.offsetWidth < expired.scrollWidth;
      } else if (notExpired) {
        this.overflow =
          notExpired.offsetHeight < notExpired.scrollHeight ||
          notExpired.offsetWidth < notExpired.scrollWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  z-index: 1;
  margin: 5px;
  @include flexCenter(row);
  .demo-user-alert {
    @include flexCenter(row);
    background: white;
    border: 3px solid color(primary);

    box-shadow: 0px 15px 45px -20px rgba(0, 0, 0, 0.75);

    // border-radius: 5px;
    width: 70vw;
    padding: 1rem 1rem;
    font-weight: bold;
    text-align: center;
    .alert-text {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      a {
        text-decoration: underline;
      }
    }
    .alert-text.more {
      -webkit-line-clamp: unset;
    }
  }
  .toggle-link {
    border: none;
    background: color(light-primary);
    color: inherit;
    padding: 0.2rem 0.5rem;
    margin-left: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>
