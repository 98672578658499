import axios from '@/lib/http';
import LocalStorageService from '@/services/LocalStorageService';
const localStorageService = LocalStorageService.getService();

const getDefaultState = () => {
  return {
    user: null,
    stripeUser: null,
    defaultCard: null,
    shows: [],
    files: [],
    buttons: [],
    passes: [],
    quizzes: [],
    templates: [],
  };
};

const state = getDefaultState();

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_SHOWS(state, shows) {
    state.shows = shows;
  },
  ADD_SHOW(state, show) {
    state.shows.push(show);
  },
  DELETE_SHOW(state, show) {
    state.shows.splice(state.shows.indexOf(show), 1);
  },
  SET_FILES(state, files) {
    state.files = files;
  },
  SET_BUTTONS(state, buttons) {
    state.buttons = buttons;
  },
  ADD_BUTTON(state, button) {
    state.buttons.push(button);
  },
  ADD_BUTTONS(state, buttons) {
    state.buttons = [...state.buttons, ...buttons].filter(
      (value, index, self) =>
        self.map(btn => btn.name).indexOf(value.name) === index,
    );
  },
  DELETE_BUTTON(state, button) {
    state.buttons.splice(state.buttons.indexOf(button), 1);
  },
  SET_PASSES(state, passes) {
    state.passes = passes;
  },
  ADD_PASS(state, pass) {
    state.passes.push(pass);
  },
  DELETE_PASS(state, uuid) {
    state.passes = state.passes.filter(pass => pass.uuid !== uuid);
  },
  SET_QUIZZES(state, quizzes) {
    state.quizzes = quizzes;
  },
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  SET_STRIPE_USER(state, user) {
    state.stripeUser = user;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.stripeUser.subscriptions.data[0] = subscription;
  },
  SET_DEFAULT_CARD(state, card) {
    state.defaultCard = card;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async LOGIN(context, body) {
    const { data } = await axios.post('/auth/login', body);
    if (data.stripeUser.subscription_status == 'inactive') {
      throw new TypeError('Subscription inactive')
    } else {
      localStorageService.setToken(data);
      context.commit('SET_USER', data.user);
      context.commit('SET_STRIPE_USER', data.stripeUser);
    }
  },
  async GET_SHOWS(context) {
    const { data } = await axios.get('/api/shows');
    context.commit('SET_SHOWS', data);
  },
  async ADD_SHOW(context, body) {
    const { data } = await axios.post('/api/shows', body);
    context.commit('ADD_SHOW', data);
  },
  async DELETE_SHOW(context, show) {
    await axios.delete(`/api/shows?show_uuid=${show.uuid}`);
    context.commit('DELETE_SHOW', show);
  },
  async GET_FILES(context) {
    const { data } = await axios.get('/api/files');
    
    context.commit('SET_FILES', data);
  },
  async GET_BUTTONS(context) {
    const { data } = await axios.get('/api/custom/buttons');
    context.commit('SET_BUTTONS', data.custombuttons);
  },
  async ADD_BUTTON(context, body) {
    const { data } = await axios.post('/api/upload/button', body);
    context.commit('ADD_BUTTON', data.button);
  },
  async DELETE_BUTTON(context, data) {
    await axios.delete('/api/custom/buttons', { params: data.body });
    context.commit('DELETE_BUTTON', data.button);
  },
  async GET_PASSES(context) {
    const { data } = await axios.get('/api/passes');
    context.commit('SET_PASSES', data);
  },
  async ADD_PASS(context, body) {
    const { data } = await axios.post('/api/passes/add', body);
    context.commit('ADD_PASS', data.pass);
  },
  async DELETE_PASS(context, uuid) {
    await axios.delete('/api/passes', { params: { uuid } });
    context.commit('DELETE_PASS', uuid);
  },
  async GET_QUIZZES(context) {
    const { data } = await axios.get('/api/quizzes');
    context.commit('SET_QUIZZES', data);
  },
  async GET_TEMPLATES(context) {
    const { data } = await axios.get('/api/buttons/gettemplates');
    context.commit('SET_TEMPLATES', data);
  },
};

const getters = {
  getShowPasses: state => show_uuid => {
    return state.passes.filter(pass => pass.show_uuid === show_uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
