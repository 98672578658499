import store from '@/store';
export default {
  install(Vue, options) {
    Vue.prototype.$message = function(
      message,
      type = 'info',
      timeout = options.timeout,
    ) {
      const msg = { type, message };
      store.commit('message/ADD_MESSAGE', msg);
      setTimeout(() => {
        store.commit('message/DELETE_MESSAGE', msg);
      }, timeout);
    };

    Vue.prototype.$infoMessage = function(message, timeout = options.timeout) {
      const msg = { type: 'info', message };
      store.commit('message/ADD_MESSAGE', msg);
      setTimeout(() => {
        store.commit('message/DELETE_MESSAGE', msg);
      }, timeout);
    };

    Vue.prototype.$successMessage = function(
      message,
      timeout = options.timeout,
    ) {
      const msg = { type: 'success', message };
      store.commit('message/ADD_MESSAGE', msg);
      setTimeout(() => {
        store.commit('message/DELETE_MESSAGE', msg);
      }, timeout);
    };

    Vue.prototype.$errorMessage = function(message, timeout = options.timeout) {
      const msg = { type: 'error', message };
      store.commit('message/ADD_MESSAGE', msg);
      setTimeout(() => {
        store.commit('message/DELETE_MESSAGE', msg);
      }, timeout);
    };

    Vue.prototype.$createLoading = function(message) {
      const msg = { type: 'loading', message };
      store.commit('message/ADD_MESSAGE', msg);
      return msg;
    };

    Vue.prototype.$deleteLoading = function(msg) {
      store.commit('message/DELETE_MESSAGE', msg);
    };
  },
};
