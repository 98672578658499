import axios from '@/lib/http';

const getDefaultState = () => {
  return {
    file: null,
    type: null,
  };
};

const state = getDefaultState();

const mutations = {
  SET_FILE(state, fileObj) {
    state.file = fileObj.file;
    state.type = fileObj.type;
  },
  SET_AUDIO(state, audio) {
    state.file.audio = audio;
  },
  SET_LOOP_AUDIO(state, loop_audio) {
    state.file.loop_audio = loop_audio;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async UPDATE_IMAGE_START(context, body) {
    await axios.post('/api/start/image', body);
    context.commit('show/SET_IMAGE_FILE_START', body, { root: true });
  },
  async UPDATE_VIDEO_START(context, body) {
    await axios.post('/api/start/video', body);
    context.commit('show/SET_VIDEO_FILE_START', body, { root: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
