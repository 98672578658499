import prices from '@/data/prices';
import products from '@/data/products';

const silverId =
  process.env.NODE_ENV === 'production'
    ? 'prod_JD5vKnEGnufRGJ' //live product id
    : 'prod_IkEwZigT2yj824'; //test product id
const silverProduct = products.find(p => p.id === silverId);

const silverMonth = prices.find(
  p => p.product === silverProduct && p.recurring.interval === 'month',
);

export default {
  product: {
    id: silverProduct.id,
    trial: true,
    name: '360Editor Silver - Free Trial',
    description:
      'Web based 360editor and publishing platform for VR-Content. Trial shows have watermark on them.',
    details: [
      '30 days of free trial',
      'Watermark on top of shows',
      ...silverProduct.details,
    ],
    images: ['https://files.stripe.com/links/fl_test_WY31xkxOAA0b4n6wIwx2EPGE'],
    maxShows: 5,
  },
  price: silverMonth,
};
