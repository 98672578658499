import axios from 'axios';
import router from '@/router';
import LocalStorageService from '@/services/LocalStorageService';
const localStorageService = LocalStorageService.getService();

// const API_URL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://360editor-back-testenv.azurewebsites.net/'
//     : 'http://localhost:8000';
// const API_URL = 'https://dev-dot-platinum-voice-204309.ey.r.appspot.com';

const currentUrl = location.host;
let API_URL = '';


if (currentUrl==='test.360editor.fi'){
  console.log('TEST ENV');
  // Test env
  API_URL = 'https://360editor-back-testenv.azurewebsites.net/';
} else if (currentUrl==='localhost:8080') {
  console.log('LOCAL ENV');
  API_URL = 'http://localhost:8000';
} else {
  console.log('PROD ENV');
  API_URL = 'https://360editor-back-test.azurewebsites.net/';
}

const api = axios.create({
  baseURL: API_URL,
});

const cancelSource = axios.CancelToken.source();

api.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.cancelToken = cancelSource.token;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

let retry = false;

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const refreshToken = localStorageService.getRefreshToken();
    const token = localStorageService.getAccessToken();

    if (retry && error.response.status === 401) {
      if (originalRequest.url === '/auth/token') {

        localStorageService.clearTokens();
        router
          .push({
            name: 'login',
            query: { error: 'Session expired, please login again.' },
          })
          .catch(() => {});
      } else if (originalRequest.url.includes('/editorial-pass/')) {
        router
          .push({
            name: 'login',
            query: { error: 'Editorial pass has expired.' },
          })
          .catch(() => {});
      }
      retry = false;
    } else if (!retry && error.response.status === 401) {
      retry = true;
      const res = await api.post('/auth/token', {
        token,
        refreshToken,
      });

      if (res.status === 201) {
        localStorageService.setToken(res.data);
        retry = false;
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);
export default api;
