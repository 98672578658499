<template>
  <div id="app">
    <TheNavbar />
    <DemoUserAlert v-if="$route.path !== '/expired'" />
    <router-view />
    <Messages />
    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from './components/TheNavbar';
import DemoUserAlert from './components/DemoUserAlert';
import Messages from './components/Messages';
import TheFooter from './components/TheFooter';

export default {
  name: 'App',
  components: {
    TheNavbar,
    DemoUserAlert,
    Messages,
    TheFooter,
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
  margin: 0;
  padding: 0;
}

/* BUTTON WITH CORNER COLOR FLIP FX NEEDS BOTH mediataloBtnFx AND cornerFx CLASSES TO WORK */
.mediataloBtnFx {
  color: black !important;
  outline: none;
  background: transparent;
  line-height: 2;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  transition: all 0.5s;
}

.mediataloBtnFx:before,
.mediataloBtnFx:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: black;
  z-index: -1;
  transform: translate3D(0, -100%, 0);
  transition: all 0.5s;
}

.mediataloBtnFx:before {
  background: color(primary) !important;
  transform: translate3D(0, 0, 0);
}

.mediataloBtnFx:enabled:hover {
  color: white !important;
}
.mediataloBtnFx:disabled:hover {
  color: black;
  cursor: default;
}

.mediataloBtnFx:enabled:hover:after {
  transform: translate3D(0, 0, 0);
  transition: all 0.5s;
}

.cornerFx:enabled:after {
  width: 200%;
  transform-origin: 0 bottom;
  transform: translate3D(-50%, 0, 0) rotate(-50deg);
}

.cornerFx:enabled:hover:after {
  transform: rotate(0deg);
  transition: all 0.5s;
}

/* NORMAL BUTTON FX */
.mediataloBtn {
  background: color(primary) !important;
  color: #2c3e50 !important;
  transition: all 0.5s;

  &:hover {
    background: black !important;
    color: white !important;
  }
}

.btn-sm {
  font-size: 12px;
}

select {
  height: 48px;
}

.close {
  cursor: pointer;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: color(gray);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  padding: 0px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 25px;
  background: color(primary);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 5px;
  height: 25px;
  background: color(primary);
  cursor: pointer;
}

.help:hover {
  cursor: pointer;
}

.trash,
.add,
.preview,
.results,
.edit {
  width: 40px;
  height: 40px;
  border-radius: 10%;
}
.add {
  padding: 2px;
}
.preview,
.results {
  padding: 3px;
}
.trash,
.edit {
  padding: 4px;
}
.trash:hover,
.add:hover,
.preview:hover,
.results:hover,
.edit:hover {
  background: color(gray);
}

.sub-heading {
  color: color(primary);
}

// input,
// select {
//   background: color(very-light-gray);
//   padding: 1vw 1vw;
//   font-weight: 500;
//   border: none;
//   border-radius: 2%;
// }
* {
  &:focus,
  &:active {
    outline: none;
  }
}
select {
  background: color(very-light-gray);
  border: none;
  padding: 0;
  padding-left: 1vw;
}

// Button groups
.button-group {
  display: flex;
  flex-wrap: wrap;
  @include desktop() {
    display: block;
  }
  .button.active {
    background: color(light-primary);
    color: black;
    box-shadow: 0px 0px 0px 1px black;
  }
  @include button;
}
@include button;
</style>
