import axios from '@/lib/http';
const getDefaultState = () => {
  return {
    show: null,
    files: [],
    characters: [],
    images: [],
    videos: [],
    editorialPasses: [],
    startFile: null,
    percentageOfDataUsed: 0,
    empty: true,
    languages:[],
    useAnimation: 'false',
  };
};

const state = getDefaultState();

const mutations = {
  SET_SHOW(state, show) {
    state.show = show;
  },
  SET_FILES(state, files) {
    state.files = files;
  },
  SET_CHARACTERS(state, files) {
    state.characters = files;
  },
  SET_LANGUAGES(state, languages) {
    state.languages = Object.values(languages);
  },
  SET_IMAGES(state, images) {
    const start = images.find(image => image.name.startsWith('ShowStart_'));
    if (start) {
      images.splice(images.indexOf(start), 1);
      state.images = [start, ...images];
    } else {
      state.images = images;
    }
  },
  SET_VIDEOS(state, videos) {
    const start = videos.find(
      video => video.name.split('_')[0] === 'ShowStart',
    );
    if (start) {
      videos.splice(videos.indexOf(start), 1);
      state.videos = [start, ...videos];
    } else {
      state.videos = videos;
    }
  },
  SET_EDITORIAL_PASSES(state, editorialPasses) {
    state.editorialPasses = editorialPasses;
  },
  SET_START_FILE(state, file) {
    state.startFile = file;
  },
  SET_PERCENTAGE(state, percentage) {
    state.percentageOfDataUsed = percentage;
  },
  SET_EMPTY(state, bool) {
    state.empty = bool;
  },
  SET_IMAGE_FILE_START(state, imageObj) {
    const image = state.images.find(
      image => image.uuid === imageObj.image_uuid,
    );
    if (image) {
      image.start_pos = imageObj.slider;
    }
  },
  SET_VIDEO_FILE_START(state, videoObj) {
    const video = state.videos.find(
      video => video.uuid === videoObj.video_uuid,
    );
    if (video) {
      video.start_pos = videoObj.slider;
    }
  },
  SET_SHOW_SPLASH(state, url) {
    state.show.splash_url = url;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async GET_SHOW(context, show_uuid) {
    const { data } = await axios.get(`/api/shows/${show_uuid}`);
    context.commit('SET_SHOW', data);
  },
  async GET_FILES(context, show) {
    const { data } = await axios.get(
      `/api/show/files?show=${show.name}&show_uuid=${show.uuid}`,
    );
    context.commit('SET_FILES', data);
  },
  async GET_CHARACTERS(context, show) {
    let body = {
      show:show,
    }
    const chars = await axios.post('/api/shows/getshowcharacters', body);
    context.commit('SET_CHARACTERS', chars.data);
  },
  async GET_LANGUAGES(context, show_uuid) {
    const body = {
      show: show_uuid,
    };
    const { data } = await axios.post('/api/buttons/getlanguages', body);
    context.commit('SET_LANGUAGES', data);
  },
  // async GET_ANIMATIONUSE(context, show_uuid) {
  //   const body = {
  //     show: show_uuid,
  //   };
  //   const { data } = await axios.post('/api/buttons/getlanguages', body);
  //   context.commit('SET_LANGUAGES', data);
  // },
  async GET_IMAGES({ commit, state }, show) {
    const { data } = await axios.get('/api/show/images', {
      params: { show_uuid: show.uuid, show: show.name },
    });
    commit('SET_IMAGES', data);
    setSelectedFile(commit, state);
  },
  async GET_VIDEOS({ commit, state }, show) {
    const { data } = await axios.get('/api/show/videos', {
      params: { show_uuid: show.uuid, show: show.name },
    });
    commit('SET_VIDEOS', data);
    setSelectedFile(commit, state);
  },
  async GET_EDITORIAL_PASSES(context, show_uuid) {
    const { data } = await axios.get('/api/editorial-pass/passes', {
      params: { show_uuid },
    });
    context.commit('SET_EDITORIAL_PASSES', data);
  },
  async GET_PERCENTAGE_OF_DATA_USED(context, show) {
    const { data } = await axios.get(`/api/shows/${show.name}/data`);
    context.commit('SET_PERCENTAGE', data.percentage);
  },
  async RELOAD_DATA({ dispatch, commit, state }) {
    const promises = [];
    dispatch('GET_PERCENTAGE_OF_DATA_USED', state.show);
    promises.push(dispatch('GET_FILES', state.show));
    promises.push(dispatch('GET_IMAGES', state.show));
    promises.push(dispatch('GET_VIDEOS', state.show));
    promises.push(dispatch('GET_CHARACTERS', state.show));
    await Promise.all(promises);
    setSelectedFile(commit, state);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function setSelectedFile(commit, state) {
  const allFiles = [...state.images, ...state.videos];
  const startFile =
    allFiles.find(file => file.name.startsWith('ShowStart_')) || null;

  if (startFile) {
    const type = startFile.path.includes('/rooms/') ? 'image' : 'video';
    const fileObj = {
      file: startFile,
      type,
    };
    commit('SET_START_FILE', startFile);
    commit('selectedFile/SET_FILE', fileObj, { root: true });
    commit('SET_EMPTY', false);
  } else {
    commit('SET_START_FILE', null);
    if (state.images.length > 0) {
      const fileObj = {
        file: state.images[0],
        type: 'image',
      };
      commit('selectedFile/SET_FILE', fileObj, { root: true });
      commit('SET_EMPTY', false);
    } else if (state.videos.length > 0) {
      const fileObj = {
        file: state.videos[0],
        type: 'video',
      };
      commit('selectedFile/SET_FILE', fileObj, { root: true });
      commit('SET_EMPTY', false);
    } else {
      commit('SET_EMPTY', true);
    }
  }
}
