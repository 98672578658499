import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import Vuelidate from 'vuelidate';
// import { VuelidatePlugin } from '@vuelidate/core'; // use with composition api
import '../node_modules/bootswatch/dist/lux/bootstrap.min.css';
import 'jquery';
// import 'popper.js';
// import 'bootstrap';
import MessagePlugin from '@/plugins/MessagePlugin';
import VCalendar from 'v-calendar';

Vue.use(Vuelidate);
//Vue.use(VuelidatePlugin); // use with composition api
Vue.use(MessagePlugin, { timeout: 3500 });
Vue.use(VCalendar);
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
