

const getDefaultState = () => {
  return {
    assignedGroups: [],
    grouplist: [],
  };
};

const state = getDefaultState();

const mutations = {
  UPDATE_GROUPS(state, newGroups){
    state.assignedGroups = newGroups;
  },
  UPDATE_GROUPLIST(state, newGroups){
    state.grouplist = newGroups;
  },
};



const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  // actions,
};
