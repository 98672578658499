import freeTrial from '@/data/freeTrial';
import prices from '@/data/prices';

const getDefaultState = () => {
  return {
    selectedProduct: null,
    selectedInterval: 'month',
    selectedPrice: null,
    coupon: null,
    couponId: null,
  };
};

const state = getDefaultState();

const mutations = {
  setSelectedProduct(state, product) {
    state.selectedProduct = product;
  },
  setSelectedInterval(state, interval) {
    state.selectedInterval = interval;
  },
  setSelectedPrice(state, price) {
    state.selectedPrice = price;
  },
  setCoupon(state, coupon) {
    state.coupon = coupon;
  },
  setCouponId(state, couponId) {
    state.couponId = couponId;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  selectProductAndPrice({ commit, state }, product) {
    commit('setSelectedProduct', product);
    if (product === 'freeTrial') {
      const price = prices.find(
        p =>
          p.product === freeTrial.product.id &&
          p.recurring.interval === 'month',
      );
      commit('setSelectedPrice', price);
    } else {
      const price = prices.find(
        p =>
          p.product === product.id &&
          p.recurring.interval === state.selectedInterval,
      );
      commit('setSelectedPrice', price);
    }
  },
  selectIntervalAndPrice({ commit, state }, interval) {
    commit('setSelectedInterval', interval);
    const price = prices.find(
      price =>
        price.product === state.selectedProduct.id &&
        price.recurring.interval === interval,
    );
    commit('setSelectedPrice', price);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
